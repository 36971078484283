<template>
  <v-card>
    <v-container>
      <v-card-title>
        <span class="pl-1 primary--text">{{ titleAfi }}</span>
      </v-card-title>
      <v-card-text>
        <v-card>
          <v-container>
            <v-row>
              <!-- Número afiliado -->
              <v-col cols="4" sm="6" md="3">
                <v-text-field
                  type="text"
                  outlined
                  dense
                  filled
                  readonly
                  label="N° de afiliado"
                  v-model="numeroAfi"
                  autocomplete="off"
                  disabled
                ></v-text-field>
              </v-col>
              <!-- Tipo y num de doc. -->
              <v-col cols="4" sm="6" md="3">
                <v-text-field
                  type="text"
                  outlined
                  dense
                  filled
                  readonly
                  label="Tipo y N° de documento"
                  v-model="documento"
                  autocomplete="off"
                  disabled
                ></v-text-field>
              </v-col>
              <!-- Afiliado -->
              <v-col cols="4" sm="6" md="5">
                <v-text-field
                  type="text"
                  outlined
                  dense
                  filled
                  readonly
                  label="Apellido y nombre"
                  v-model="afiNom"
                  autocomplete="off"
                  disabled
                ></v-text-field>
              </v-col>
            </v-row>
          </v-container>
        </v-card>
        <v-card class="mt-2">
          <v-container>
            <v-col col="12" class="p-0 p-0" align="start" align-self="center">
              <v-btn-toggle dense v-model="text" color="primary" group>
                <v-btn value="DATOS" @click="pestañaSelect(1)"> Datos </v-btn>
                <v-btn value="DOCUMENTACION" @click="pestañaSelect(2)">
                  Documentación
                </v-btn>
              </v-btn-toggle>
            </v-col>
            <div v-if="mostrarDatos">
              <v-row class="mt-3">
                <!-- Tipo cobertura -->
                <v-col cols="4" class="py-0" md="5">
                  <v-text-field
                    v-model="tipoCoberturaSelected"
                    label="Tipo de cobertura"
                    outlined
                    filled
                    readonly
                    disabled
                    dense
                  >
                  </v-text-field>
                </v-col>
                <!-- Vigencia desde -->
                <v-col cols="4" sm="6" md="3" class="py-0">
                  <v-menu max-width="290px" min-width="290px">
                    <template v-slot:activator="{}">
                      <v-text-field
                        v-model="fechaVigenciaDesde"
                        label="Vigencia desde"
                        :append-icon="calendarIcon"
                        disabled
                        outlined
                        readonly
                        dense
                        filled
                      ></v-text-field>
                    </template>
                  </v-menu>
                </v-col>
                <!-- Vigencia hasta -->
                <v-col cols="4" sm="6" md="3" class="py-0">
                  <v-menu max-width="290px" min-width="290px">
                    <template v-slot:activator="{}">
                      <v-text-field
                        v-model="fechaVigenciaHasta"
                        label="Vigencia hasta"
                        :append-icon="calendarIcon"
                        outlined
                        autocomplete="not"
                        dense
                        filled
                        readonly
                        disabled
                      ></v-text-field>
                    </template>
                  </v-menu>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="4" class="py-0" md="3">
                  <v-text-field
                    type="text"
                    outlined
                    dense
                    clearable
                    filled
                    disabled
                    readonly
                    label="Número"
                    v-model="numero"
                    autocomplete="off"
                  ></v-text-field>
                </v-col>
                <v-col cols="4" class="py-0" md="3">
                  <v-text-field
                    type="text"
                    outlined
                    dense
                    filled
                    readonly
                    disabled
                    label="CUD"
                    v-model="codigoDiscapacidad"
                    autocomplete="off"
                  ></v-text-field>
                </v-col>
                <v-col cols="4" class="py-0" md="5">
                  <v-text-field
                    type="text"
                    outlined
                    dense
                    disabled
                    filled
                    readonly
                    label="Matrícula de médico de referencia"
                    v-model="medicoRef"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <!-- Observaciones -->
                <v-col cols="11" class="pt-0 ">
                  <v-textarea
                    outlined
                    dense
                    disabled
                    filled
                    readonly
                    auto-grow
                    color="black"
                    rows="1"
                    v-model="observaciones"
                    label="Observaciones"
                  ></v-textarea>
                </v-col>
              </v-row>
            </div>
            <div v-else>
              <v-card-title>
                <span class="pl-1 primary--text"
                  >Tipo de cobertura: {{ tipoCober }}</span
                >
              </v-card-title>
              <v-data-table
                :headers="headersDoc"
                :items="itemsDocumentacion"
                class="elevation-1"
                :loading="isLoading"
                item-key="cobEspDocId"
                show-expand
                :expanded.sync="expanded"
              >
                <template v-slot:[`item.actions`]="{ item }">
                  <v-tooltip left v-if="editArchivos">
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon
                        v-bind="attrs"
                        size="20"
                        v-on="on"
                        @click="
                          editDoc(
                            item,
                            item.tcobDocId,
                            item.cobEspDocId,
                            item.tCobDocNombre,
                            item.estado,
                            item.observaciones,
                            item.tcobDocEstadoId
                          )
                        "
                      >
                        {{ editIcon }}
                      </v-icon>
                    </template>
                    <span>Editar</span>
                  </v-tooltip>
                  <v-tooltip left v-if="seeArchivosAdjuntos">
                    <template v-slot:activator="{ on, attrs }">
                      <section style="display: inline" v-on="on">
                        <v-icon
                          v-bind="attrs"
                          size="20"
                          v-on="on"
                          :disabled="!docGenerada"
                          @click="
                            verArchivosAdjuntos(
                              item.tcobDocId,
                              item.cobEspDocId,
                              item.tCobDocNombre,
                              item.estado,
                              item.observaciones
                            )
                          "
                        >
                          {{ seeIcon }}
                        </v-icon>
                      </section>
                    </template>
                    <span v-if="docGenerada">Ver archivos adjuntos</span>
                    <span v-else
                      >La cobertura no posee archivos adjuntos para ver</span
                    >
                  </v-tooltip>
                </template>
                <template
                  v-slot:[`item.data-table-expand`]="{ item, isExpanded }"
                >
                  <v-tooltip right>
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon
                        @click="handleExpansion(item, isExpanded)"
                        v-bind="attrs"
                        size="20"
                        v-show="banderaOpenArchivo"
                        v-on="on"
                        >{{
                          isExpanded ? chevronUpIcon : chevronDownIcon
                        }}</v-icon
                      >
                    </template>
                    <span>Ver observaciones y datos de usuario</span>
                  </v-tooltip>
                </template>
                <template v-slot:expanded-item="{ headers, item }">
                  <td :colspan="headers.length">
                    <v-row>
                      <v-col cols="12" md="12">
                        <strong>Observaciones:</strong>
                        {{ item.observaciones }}
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="3" md="3">
                        <strong>Usuario alta:</strong>
                        {{ item.usuAlta }}
                      </v-col>
                      <v-col cols="3" md="3">
                        <strong>Fecha alta:</strong>
                        {{ item.fecAlta }}
                      </v-col>
                      <v-col cols="3" md="3">
                        <strong>Usuario modificación:</strong>
                        {{ item.usuModi }}
                      </v-col>
                      <v-col cols="3" md="3">
                        <strong>Fecha modificación:</strong>
                        {{ item.fecModi }}
                      </v-col>
                    </v-row>
                  </td>
                </template>
              </v-data-table>
            </div>
          </v-container>
        </v-card>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn outlined @click="closeModal"> Cerrar </v-btn>
        </v-card-actions>
      </v-card-text>

      <v-dialog
        v-if="openEditDoc"
        v-model="openEditDoc"
        max-width="60%"
        @keydown.esc="closeModalEditDoc"
        persistent
      >
        <EditDocumentacion
          :tipoCobId="tipoCobId"
          :editDocu="editDocu"
          :cobEspId="cobEspId"
          :cobEspDocId="cobEspDocId"
          :tcobDocEstadoId="tcobDocEstadoId"
          :tcobDocId="tcobDocId"
          :tCobDocNombre="tCobDocNombre"
          :deleteArchivos="deleteArchivos"
          :estado="estado"
          :adjuntarFile="adjuntarFile"
          @closeModalEditDoc="closeModalEditDoc"
          @loadDocumentacion="loadDocumentacion"
        ></EditDocumentacion
      ></v-dialog>
      <v-dialog
        v-if="openModalVerArchivos"
        v-model="openModalVerArchivos"
        max-width="65%"
        @keydown.esc="closeModalVerArchivos"
        persistent
      >
        <VerArchivosAdjuntosTCobertura
          :tCobDocNombre="tCobDocNombre"
          :tcobDocId="tcobDocId"
          :cobEspDocId="cobEspDocId"
          :estado="estado"
          :observaciones="observaciones"
          @closeModalVerArchivos="closeModalVerArchivos"
        ></VerArchivosAdjuntosTCobertura
      ></v-dialog>
    </v-container>
  </v-card>
</template>
<script>
import { mapActions } from "vuex";
import enums from "@/utils/enums/index.js";
import { mask } from "vue-the-mask";
import AdjuntarArchivoCobEspecial from "@/components/modules/afiliaciones/afiliados/AdjuntarArchivoCobEspecial.vue";
import EditDocumentacion from "@/components/modules/afiliaciones/afiliados/EditDocumentacion.vue";
import VerArchivosAdjuntosTCobertura from "@/components/modules/afiliaciones/afiliados/VerArchivosAdjuntosTCobertura.vue";
export default {
  name: "VerCoberturasEspeciales",
  directives: { mask },
  components: {
    AdjuntarArchivoCobEspecial,
    EditDocumentacion,
    VerArchivosAdjuntosTCobertura
  },
  props: {
    cobEspId: {
      type: Number,
      required: true
    },
    tipoCober: {
      type: String,
      required: true
    }
  },
  data: vm => ({
    titleAfi: enums.titles.VER_COBERTURAS_ESPECIALES,
    adjuntar: enums.icons.ATTACH,
    seeIcon: enums.icons.SEE,
    optionCodeVerCober:
      enums.modules.adminSistema.permissions.VER_COBERTURAS_ESPECIALES,
    chevronUpIcon: enums.icons.CHEVRON_UP,
    chevronDownIcon: enums.icons.CHEVRON_DOWN,
    editIcon: enums.icons.EDIT,
    openModalVerArchivos: false,
    title: "Datos del afiliado",
    expanded: [],
    cobEspDocId: null,
    tcobDocId: null,
    numeroAfi: null,
    isLoading: false,
    itemsDocumentacion: [],
    fechaVigenciaHasta: null,
    fechaVigenciaDesde: null,
    tipoCoberturaSelected: null,
    numero: null,
    codigoDiscapacidad: null,
    observaciones: null,
    text: "DATOS",
    openEditDoc: false,
    documento: null,
    afiNom: null,
    editDocu: {},
    tipoCobId: null,
    medicoRef: null,
    mostrarDatos: true,
    mostrarDocumentacion: true,
    calendarIcon: enums.icons.CALENDAR,
    banderaOpenArchivo: false,
    adjuntarFile: false,
    editArchivos: false,
    deleteArchivos: false,
    seeArchivosAdjuntos: false,
    allowedActions: [],
    estado: null,
    docGenerada: false,
    tCobDocNombre: null,
    headersDoc: [
      {
        text: "Documentación",
        align: "start",
        value: "tCobDocNombre"
      },
      {
        text: "Estado",
        align: "start",
        sortable: false,
        value: "estado"
      },
      { text: "Acciones", value: "actions", sortable: false, align: "end" },
      { text: "", value: "data-table-expand", align: "end" }
    ]
  }),
  created() {
    this.loadDatosAfiliado();
    this.loadDocumentacion();
    this.loadPermissions();
  },
  methods: {
    ...mapActions({
      getDatosCoberturaEspecialById:
        "afiliaciones/getDatosCoberturaEspecialById",
      getDocumentacionCoberturaEspAfiliado:
        "afiliaciones/getDocumentacionCoberturaEspAfiliado",
      getPermissionsByOptionCode:
        "AdministracionSistema/getPermissionsByOptionCode",
      setAlert: "user/setAlert"
    }),
    async loadPermissions() {
      const resp = await this.getPermissionsByOptionCode(
        this.optionCodeVerCober
      );
      this.allowedActions = resp.data;
      this.setPermisos();
    },
    setPermisos() {
      this.allowedActions?.forEach(x => {
        switch (x.optionCode) {
          case enums.modules.adminSistema.permissions.ADJUNTAR_ARCHIVO:
            this.adjuntarFile = true;
            break;
          case enums.modules.adminSistema.permissions.EDITAR_DOCUMENTACION:
            this.editArchivos = true;
            break;
          case enums.modules.adminSistema.permissions.VER_ARCHIVOS_ADJ:
            this.seeArchivosAdjuntos = true;
            break;
          case enums.modules.adminSistema.permissions.DELETE_ARCHIVOS:
            this.deleteArchivos = true;
            break;
          default:
            break;
        }
      });
    },
    async loadDatosAfiliado() {
      const response = await this.getDatosCoberturaEspecialById(this.cobEspId);
      this.afiNom = response.benNom;
      this.numeroAfi = response.benNumero;
      this.documento = response.benTipoNroDoc;
      this.tipoCoberturaSelected = response.tipoCobertura;
      this.fechaVigenciaDesde = response.vigenciaDesde;
      this.fechaVigenciaHasta = response.vigenciaHasta;
      this.numero = response.numeroCobertura;
      this.codigoDiscapacidad = response.cud;
      this.medicoRef = response.medicoReferencia;
      this.observaciones = response.observaciones;
      this.tipoCobId = response.idTipoCob;
    },
    async loadDocumentacion() {
      this.isLoading = true;
      const response = await this.getDocumentacionCoberturaEspAfiliado(
        this.cobEspId
      );
      this.docGenerada = response.data.data.documentacionGenerada;
      this.itemsDocumentacion =
        response.data.data.coberturaEspecialAfiliadoDocs;
      if (response.data.data.documentacionGenerada) {
        this.banderaOpenArchivo = true;
      }
      this.isLoading = false;
    },
    handleExpansion(item, state) {
      const itemIndex = this.expanded.indexOf(item);
      state ? this.expanded.splice(itemIndex, 1) : this.expanded.push(item);
    },
    pestañaSelect(num) {
      switch (num) {
        case 1:
          this.mostrarDatos = true;
          this.mostrarDocumentacion = false;
          break;
        case 2:
          this.mostrarDatos = false;
          this.mostrarDocumentacion = true;
          break;
        default:
          break;
      }
    },
    verArchivosAdjuntos(
      tcobDocId,
      cobEspDocId,
      tCobDocNombre,
      estado,
      observaciones
    ) {
      this.openModalVerArchivos = true;
      this.tcobDocId = tcobDocId;
      this.cobEspDocId = cobEspDocId;
      this.tCobDocNombre = tCobDocNombre;
      this.estado = estado;
      this.observaciones = observaciones;
    },
    closeModalVerArchivos() {
      this.openModalVerArchivos = false;
    },
    editDoc(
      item,
      tcobDocId,
      cobEspDocId,
      tCobDocNombre,
      estado,
      observaciones,
      tcobDocEstadoId
    ) {
      this.openEditDoc = true;
      this.editDocu = item;
      this.tcobDocId = tcobDocId;
      this.cobEspDocId = cobEspDocId;
      this.tCobDocNombre = tCobDocNombre;
      this.estado = estado;
      this.observaciones = observaciones;
      this.tcobDocEstadoId = tcobDocEstadoId;
      this.deleteArchivos = this.deleteArchivos;
      this.adjuntarFile = this.adjuntarFile;
    },
    closeModalEditDoc() {
      this.openEditDoc = false;
    },

    closeModal() {
      this.$emit("closeModalVerDetalle");
    }
  }
};
</script>

<style scoped>
::v-deep .theme--light.v-input input {
  color: rgb(56, 56, 56);
}
::v-deep .theme--light.v-input--is-disabled textarea {
  color: rgb(56, 56, 56);
}

::v-deep .v-select__selection,
.v-select__selection--comma,
.v-select__selection--disabled input {
  color: rgb(0, 0, 0) !important;
}

::v-deep .v-input__slot {
  cursor: context-menu !important;
}
.my-row {
  max-height: 80px;
}
.v-toolbar__content {
  height: 40px !important;
}
::v-deep input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
::v-deep input[type="number"] {
  -moz-appearance: textfield;
}
.icon-readonly {
  cursor: context-menu;
}
.my-tooltip {
  white-space: pre-line;
}
::v-deep label {
  margin-bottom: 0;
}
.fontsize {
  font-size: 12px;
}

.no-upper-case {
  text-transform: none;
}
</style>
