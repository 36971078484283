<template>
  <v-card>
    <v-container>
      <v-card-title class="pl-1 primary--text"
        >{{ formEditTitle }}: {{ this.tCobDocNombre }}
      </v-card-title>
      <v-form
        v-model="isFormValid"
        ref="form"
        form="form"
        id="form"
        @submit.prevent="saveDoc()"
      >
        <v-card class="mx-auto m-3 p-2" max-width="1200" outlined>
          <v-row>
            <v-col cols="5" md="4" sm="4" class="py-0">
              <v-select
                v-model="estadoSelected"
                :items="itemsEstado"
                item-text="value"
                item-value="id"
                :rules="rules.required"
                label="Estado"
                outlined
                dense
              >
              </v-select>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" class="pb-0">
              <v-textarea
                v-model="observacionDoc"
                class="pt-0 py-0"
                auto-grow
                :rules="[rules.maxLength(observacionDoc, 200)]"
                label="Observaciones"
                rows="1"
                dense
                outlined
              ></v-textarea>
            </v-col>
          </v-row>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              type="submit"
              :loading="saveLoading"
              :disabled="!isFormValid || saveLoading"
              form="form"
              color="primary"
            >
              Guardar
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-form>
      <v-card-title class="text-h6 primary--text pl-2">
        {{ archivosTitle }}
      </v-card-title>
      <v-data-table
        :headers="headers"
        :items="itemsDetalleArchivosAdjuntos"
        class="elevation-1"
        :loading="isLoading"
        no-data-text="No hay datos para este detalle"
      >
        <template v-slot:top>
          <v-toolbar flat>
            <v-row>
              <v-col align="end">
                <v-tooltip top v-if="adjuntarFile">
                  <template v-slot:activator="{ on, attrs }">
                    <section style="display: inline" v-on="on">
                      <v-btn
                        class="mt-2 py-4 fontsize to-right"
                        outlined
                        v-bind="attrs"
                        v-on="on"
                        small
                        :disabled="coberturaEspDocId == null"
                        color="primary"
                        @click="adjuntarArchivo()"
                      >
                        <v-icon left>{{ adjuntar }}</v-icon>
                        adjuntar
                      </v-btn>
                    </section>
                  </template>
                  <span>Adjuntar archivo</span>
                </v-tooltip>
              </v-col>
              <div v-if="coberturaEspDocId == null">
                <v-col class="pa-0 mt-5 mr-6">
                  <v-tooltip right max-width="20%">
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon v-bind="attrs" v-on="on">
                        {{ infoIcon }}
                      </v-icon>
                    </template>
                    <span>
                      Para adjuntar un archivo, debe asignar un estado en el
                      detalle de la cobertura especial
                    </span>
                  </v-tooltip>
                </v-col>
              </div>
            </v-row>
          </v-toolbar>
        </template>
        <template v-slot:[`item.actions`]="{ item }">
          <v-tooltip left>
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                v-bind="attrs"
                size="20"
                v-on="on"
                @click="downloadArchivo(item.asId, item.asTitulo)"
              >
                {{ abrirArchivo }}
              </v-icon>
            </template>
            <span>Descargar archivo</span>
          </v-tooltip>
          <v-tooltip left v-if="deleteArchivos">
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                v-bind="attrs"
                size="20"
                v-on="on"
                @click="deleteArchivo(item.asId)"
              >
                {{ deleteIcon }}
              </v-icon>
            </template>
            <span>Eliminar archivo</span>
          </v-tooltip>
        </template>
      </v-data-table>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn outlined @click="closeModal()" class="to-right">
          Cerrar
        </v-btn>
      </v-card-actions>
      <v-dialog
        v-if="openModalAdjuntar"
        v-model="openModalAdjuntar"
        max-width="45%"
        @keydown.esc="closeModalAdjuntar"
        persistent
      >
        <AdjuntarArchivoCobEspecial
          :tcobDocId="tcobDocId"
          :cobEspDocId="coberturaEspDocId"
          @closeModalAdjuntar="closeModalAdjuntar"
          @setArchivosAdjuntos="setArchivosAdjuntos"
        ></AdjuntarArchivoCobEspecial
      ></v-dialog>
    </v-container>
    <DeleteDialog
      :titleProp="titleDelete"
      :maxWidth="'35%'"
      :openDelete.sync="showDeleteModal"
      @onDeleteItem="confirmDelete()"
    />
  </v-card>
</template>

<script>
import { mapActions } from "vuex";
import rules from "@/utils/helpers/rules";
import DeleteDialog from "@/components/shared/DeleteDialog";
import AdjuntarArchivoCobEspecial from "@/components/modules/afiliaciones/afiliados/AdjuntarArchivoCobEspecial.vue";
import enums from "@/utils/enums/index";

export default {
  name: "EditDocumentacion",
  components: {
    DeleteDialog,
    AdjuntarArchivoCobEspecial
  },
  props: {
    editDocu: {
      type: Object,
      required: false
    },
    cobEspDocId: {
      type: Number,
      required: false,
      default: null
    },
    tCobDocNombre: {
      type: String,
      required: true
    },
    cobEspId: {
      type: Number
    },
    estado: {
      type: String
    },
    observaciones: {
      type: String
    },
    deleteArchivos: {
      type: Boolean
    },
    adjuntarFile: {
      type: Boolean
    },
    tcobDocId: {
      type: Number
    },
    tipoCobId: {
      type: Number
    },
    itemsDocumentacion: {
      type: Array
    },
    tcobDocEstadoId: {
      type: Number
    }
  },
  data: () => ({
    isLoading: false,
    archivosTitle: "Archivos adjuntos",
    infoIcon: enums.icons.SNB_INFO,
    isFormValid: false,
    estadoSelected: null,
    itemsEstado: [],
    saveLoading: false,
    rules: rules,
    titleDelete: enums.titles.DELETE_ARCHIVO_DOCUMENTACION,
    formEditTitle: enums.titles.EDITAR_DOC,
    deleteIcon: enums.icons.DELETE,
    adjuntar: enums.icons.ATTACH,
    observacionDoc: null,
    abrirArchivo: enums.icons.OPEN_ARCHIVO,
    showDeleteModal: false,
    itemsDetalleArchivosAdjuntos: [],
    coberturaEspDocId: null,
    openModalAdjuntar: false,
    headers: [
      {
        text: "Nombre",
        value: "asTitulo",
        sortable: false
      },
      {
        text: "Observación",
        value: "asObservacion",
        sortable: false
      },
      {
        text: "Fecha",
        value: "fecAlta",
        sortable: false
      },
      {
        text: "Usuario",
        value: "usuAlta",
        sortable: false
      },
      { text: "Acciones", value: "actions", sortable: false, align: "end" }
    ],
    allowedActions: null
  }),
  mounted() {
    this.allowedActions = JSON.parse(localStorage.getItem("allowedActions"));
  },
  created() {
    this.$store.dispatch("user/changeAppLoading", {
      status: false,
      text: "",
      opaque: false
    });
    this.setEstado();
    this.setDocumentacion(this.editDocu);
    if (this.cobEspDocId) {
      this.coberturaEspDocId = this.cobEspDocId;
      this.setArchivosAdjuntos();
    }
  },
  methods: {
    ...mapActions({
      fetchTipoCoberturaDocEstado: "afiliaciones/fetchTipoCoberturaDocEstado",
      saveDocumentacionCoberturaEspecial:
        "afiliaciones/saveDocumentacionCoberturaEspecial",
      fetchArchivosDocumentacionCobertura:
        "afiliaciones/fetchArchivosDocumentacionCobertura",
      getArchivoDocCobertura: "afiliaciones/getArchivoDocCobertura",
      deleteArchivoDocumentacion: "afiliaciones/deleteArchivoDocumentacion",
      setAlert: "user/setAlert"
    }),
    adjuntarArchivo() {
      this.openModalAdjuntar = true;
    },
    closeModalAdjuntar() {
      this.openModalAdjuntar = false;
    },
    setDocumentacion(obj) {
      this.estadoSelected = obj.tcobDocEstadoId;
      this.observacionDoc = obj.observaciones;
    },
    async setEstado() {
      const response = await this.fetchTipoCoberturaDocEstado();
      this.itemsEstado = response;
    },
    async setArchivosAdjuntos() {
      this.isLoading = true;
      const response = await this.fetchArchivosDocumentacionCobertura(
        this.coberturaEspDocId
      );
      this.itemsDetalleArchivosAdjuntos = response;
      this.isLoading = false;
    },
    closeModal() {
      this.$emit("closeModalEditDoc");
    },
    deleteArchivo(id) {
      this.showDeleteModal = true;
      this.idToDelete = id;
    },
    async confirmDelete() {
      const response = await this.deleteArchivoDocumentacion({
        asId: this.idToDelete
      });
      if (response.status === 200) {
        this.showDeleteModal = false;
        this.setAlert({ type: "success", message: "Eliminado con éxito." });
        this.setArchivosAdjuntos();
      }
    },
    async downloadArchivo(asId, asTitulo) {
      const payload = {
        asId: asId,
        nombreArchivo: asTitulo
      };
      await this.getArchivoDocCobertura(payload);
    },
    async saveDoc() {
      this.saveLoading = true;
      const data = {
        cobEspDocId: this.cobEspDocId ? this.cobEspDocId : null,
        tcobDocId: this.tcobDocId ? this.tcobDocId : null,
        idTipoCob: this.tipoCobId,
        tcobDocEstadoId: this.estadoSelected,
        cobEspId: this.cobEspId,
        observacion: this.observacionDoc ? this.observacionDoc : "",
        archivo: ""
      };

      const res = await this.saveDocumentacionCoberturaEspecial(data);
      if (res.status === 200) {
        this.coberturaEspDocId = res.data.data;
        this.setAlert({ type: "success", message: "Guardado con éxito" });
        this.$emit("loadDocumentacion");
        this.saveLoading = false;
      }
    }
  }
};
</script>
