<template>
  <v-card>
    <v-container>
      <v-card-title class="pl-1 primary--text"
        >Detalle archivos adjuntos: {{ this.tCobDocNombre }}
      </v-card-title>
      <v-row>
        <v-col cols="5" md="4" sm="4" class="py-0">
          <v-text-field
            v-model="this.estado"
            label="Estado"
            outlined
            dense
            filled
            readonly
            disabled
          >
          </v-text-field>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="11" class="pb-0">
          <v-textarea
            v-model="this.observaciones"
            class="pt-0 py-0"
            auto-grow
            label="Observaciones"
            rows="1"
            outlined
            dense
            filled
            readonly
            disabled
          ></v-textarea>
        </v-col>
      </v-row>
      <v-data-table
        :headers="headers"
        :items="itemsDetalleArchivosAdjuntos"
        class="elevation-1"
        :loading="isLoading"
        no-data-text="No hay datos para este detalle"
      >
        <template v-slot:[`item.actions`]="{ item }">
          <v-tooltip left>
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                v-bind="attrs"
                size="20"
                v-on="on"
                @click="downloadArchivo(item.asId, item.asTitulo)"
              >
                {{ abrirArchivo }}
              </v-icon>
            </template>
            <span>Descargar archivo</span>
          </v-tooltip>
        </template>
      </v-data-table>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn outlined @click="closeModal()" class="to-right">
          Cerrar
        </v-btn>
      </v-card-actions>
      <v-dialog
        v-if="openModalAdjuntar"
        v-model="openModalAdjuntar"
        max-width="45%"
        @keydown.esc="closeModalAdjuntar"
        persistent
      >
        <AdjuntarArchivoCobEspecial
          :tcobDocId="tcobDocId"
          :cobEspDocId="cobEspDocId"
          @closeModalAdjuntar="closeModalAdjuntar"
          @loadDocumentacion="loadDocumentacion"
        ></AdjuntarArchivoCobEspecial
      ></v-dialog>
    </v-container>
    <DeleteDialog
      :titleProp="titleDelete"
      :maxWidth="'35%'"
      :openDelete.sync="showDeleteModal"
      @onDeleteItem="confirmDelete()"
    />
  </v-card>
</template>
<script>
import { mapActions } from "vuex";
import DeleteDialog from "@/components/shared/DeleteDialog";
import AdjuntarArchivoCobEspecial from "@/components/modules/afiliaciones/afiliados/AdjuntarArchivoCobEspecial.vue";
import enums from "@/utils/enums/index";

export default {
  name: "VerArchivosAdjuntosTCobertura",
  components: {
    DeleteDialog,
    AdjuntarArchivoCobEspecial
  },
  props: {
    cobEspDocId: {
      type: Number,
      required: true
    },
    tCobDocNombre: {
      type: String,
      required: true
    },
    estado: {
      type: String,
      required: true
    },
    observaciones: {
      type: String,
      required: true
    },
  },
  data: () => ({
    isLoading: false,
    estadoSelected: null,
    itemsEstado: [],
    titleDelete: enums.titles.DELETE_ARCHIVO_DOCUMENTACION,
    deleteIcon: enums.icons.DELETE,
    adjuntar: enums.icons.ATTACH,
    observacionDoc: null,
    abrirArchivo: enums.icons.OPEN_ARCHIVO,
    showDeleteModal: false,
    itemsDetalleArchivosAdjuntos: [],
    openModalAdjuntar: false,
    headers: [
      {
        text: "Nombre",
        value: "asTitulo",
        sortable: false
      },
      {
        text: "Observación",
        value: "asObservacion",
        sortable: false
      },
      {
        text: "Fecha",
        value: "fecAlta",
        sortable: false
      },
      {
        text: "Usuario",
        value: "usuAlta",
        sortable: false
      },
      { text: "Acciones", value: "actions", sortable: false, align: "end" }
    ],
    allowedActions: null
  }),
  mounted() {
    this.allowedActions = JSON.parse(localStorage.getItem("allowedActions"));
  },
  created() {
    this.$store.dispatch("user/changeAppLoading", {
      status: false,
      text: "",
      opaque: false
    });
    if (this.cobEspDocId) {
      this.setArchivosAdjuntos();
    }
  },
  methods: {
    ...mapActions({
      fetchArchivosDocumentacionCobertura:
        "afiliaciones/fetchArchivosDocumentacionCobertura",
      getArchivoDocCobertura: "afiliaciones/getArchivoDocCobertura",
      setAlert: "user/setAlert"
    }),
    closeModalAdjuntar() {
      this.openModalAdjuntar = false;
    },
    async setArchivosAdjuntos() {
      this.isLoading = true;
      const response = await this.fetchArchivosDocumentacionCobertura(
        this.cobEspDocId
      );
      this.itemsDetalleArchivosAdjuntos = response;
      this.isLoading = false;
    },
    closeModal() {
      this.$emit("closeModalVerArchivos");
    },
    async downloadArchivo(asId, asTitulo) {
      const payload = {
        asId: asId,
        nombreArchivo: asTitulo
      };
      await this.getArchivoDocCobertura(payload);
    }
  }
};
</script>
