<template>
  <v-card>
    <v-container>
      <v-card-title class="primary--text">
        Gestión de archivos
      </v-card-title>
      <v-card-text>
        <v-form
          v-model="isFormValid"
          ref="form-archivo"
          form="form-archivo"
          id="form-archivo"
          @submit.prevent="subirArchivo()"
        >
          <v-row>
            <v-col cols="12" class="py-0">
              <v-text-field
                type="text"
                outlined
                dense
                label="Título / descripción"
                v-model.trim="titulo"
                :rules="rules.required.concat([rules.requiredTrim(titulo)],rules.maxLength(titulo,50))"
                autocomplete="off"
              ></v-text-field>
            </v-col>
          </v-row>
          <!-- input adjuntar -->
          <v-row cols="12" class="py-0">
            <v-col cols="9">
              <v-file-input
                ref="file"
                v-model="file"
                id="fileUpload"
                type="file"
                dense
                :rules="rules.required"
                label="Buscar archivo"
                placeholder="Seleccione un archivo"
                outlined
                :show-size="1000"
                prepend-icon="mdi-file-find-outline"
              >
                <template v-slot:selection="{ text }">
                  <v-chip dark label small>
                    {{ text }}
                  </v-chip>
                </template>
              </v-file-input>
            </v-col>
          </v-row>
          <v-row>
            <!-- Observaciones -->
            <v-col class="pt-0">
              <v-textarea
                v-model="observaciones"
                auto-grow
                :rules="
                  observaciones !== null
                    ? [rules.maxLength(observaciones, 100)]
                    : []
                "
                dense
                outlined
                label="Observación"
                rows="1"
              ></v-textarea
            ></v-col>
          </v-row>
          <v-row>
            <v-spacer></v-spacer>
            <v-btn class="m-2" outlined @click="closeModal">
              Cerrar
            </v-btn>
            <v-btn
              class="m-2"
              type="submit"
              :disabled="!isFormValid || loadingBtn"
              form="form-archivo"
              :loading="loadingBtn"
              color="primary"
            >
              Subir
            </v-btn>
          </v-row>
        </v-form>
      </v-card-text>
    </v-container>
  </v-card>
</template>
<script>
import { mapActions } from "vuex";
import rules from "@/utils/helpers/rules";
export default {
  name: "AdjuntarArchivoCobEspecial",
  props: {
    cobEspDocId: {
      type: Number
    }
  },
  data() {
    return {
      rules: rules,
      loading: false,
      loadingBtn: false,
      titulo: "",
      isFormValid: false,
      file: null,
      observaciones: "",
      headers: []
    };
  },
  methods: {
    ...mapActions({
      guardarArchivoExpediente: "afiliaciones/guardarArchivoExpediente",
      setAlert: "user/setAlert"
    }),
    closeModal() {
      this.$emit("closeModalAdjuntar");
    },
    async subirArchivo() {
      this.loadingBtn = true;
      let formData = new FormData();
      formData.append("File", this.file);
      formData.append("CobEspDocId", this.cobEspDocId);
      formData.append("Titulo", this.titulo);
      formData.append("Observaciones", this.observaciones);
      const responseAddArchivo = await this.guardarArchivoExpediente({
        formData
      });
      if (responseAddArchivo.status === 200) {
        this.setAlert({
          type: "success",
          message: "Archivo adjuntado con éxito."
        });
        this.loadingBtn = false;
        this.closeModal();
        this.$emit("setArchivosAdjuntos");
      }
    }
  }
};
</script>
